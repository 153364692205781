<template>
  <div id="header">
    <div class="page-inner flex">
      <div class="logo"></div>
      <p class="logo-corner"></p>
      <div class="top-nav flexElm" :style="{ width: navWidth + 'px' }">
        <div class="flex" style="font-weight: 600">
          <div>{{ $route.meta.title }}</div>
          <i class="el-icon-refresh" title="刷新当前页" style="margin-left: 20px; font-size: 21px" @click="reflashCur"></i>
        </div>
        <div>
          <div class="nav-item top-vip-entry" v-for="(item, index) in funcList" :key="index" :id="'header_1_' + index">
            <div class="label-name">
              <!-- 聊天室 -->
              <el-badge
                v-if="item.id == '0'"
                :value="$store.state.msgCount"
                :class="{
                  active: '0' == $store.state.currMemut
                }"
                :max="99"
                :hidden="$store.state.msgCount <= 0"
                style="vertical-align: revert"
              >
                <router-link @click.native="check_login(item)" to="" style="padding-right: 5px">
                  {{ item.name }}
                </router-link>
              </el-badge>
              <!-- 收到投递 -->
              <el-badge
                v-else-if="item.id == '6'"
                :value="$store.state.resumeCount"
                :class="{
                  active: item.id == $store.state.currMemut
                }"
                :max="99"
                :hidden="$store.state.resumeCount <= 0"
                style="vertical-align: revert"
              >
                <router-link
                  :to="{
                    path: '/company/resume/jobApply',
                    query: { noSee: '1' }
                  }"
                  style="padding-right: 5px"
                >
                  {{ item.name }}
                </router-link>
              </el-badge>
              <router-link
                v-else
                :to="item.path"
                @click.native="funcClick(item)"
                :class="{
                  active: item.id === $store.state.currMemut
                }"
              >
                {{ item.name }}
              </router-link>
            </div>
          </div>
          <el-dropdown>
            <div class="nav-item top-vip-entry">
              <div class="label-name">帮助与反馈</div>
            </div>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item @click.native="feedback">意见反馈</el-dropdown-item>
              <el-dropdown-item @click.native="myKefu1">在线客服</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <div class="nav-item top-vip-entry">|</div>
          <el-dropdown>
            <div class="nav-item top-vip-entry">
              <div class="label-name" style="font-weight: bold">
                {{ $store.state.companyName || '' }}
                <img :src="companyLogo" />
                <i class="el-icon-arrow-down el-icon--right"></i>
              </div>
            </div>
            <el-dropdown-menu style="width: 140px" slot="dropdown">
              <el-dropdown-item>
                <router-link to="/company/manage/baseInfo">
                  <i class="el-icon-office-building"></i>
                  企业信息
                </router-link>
              </el-dropdown-item>
              <el-dropdown-item>
                <router-link to="/company/account">
                  <i class="el-icon-setting"></i>
                  账号设置
                </router-link>
              </el-dropdown-item>
              <el-dropdown-item>
                <router-link to="/company/words">
                  <i class="el-icon-picture-outline-round"></i>
                  常用语
                </router-link>
              </el-dropdown-item>
              <el-dropdown-item divided @click.native="logout1">
                <i class="el-icon-mouse"></i>
                退出登录
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </div>
    <feedback ref="Feedback"></feedback>
    <vip-dialog ref="VipDialog"></vip-dialog>
  </div>
</template>

<script>
import { logout } from '@/utils/js/im.js';
export default {
  components: {
    Feedback: () => import('@/views/company/account/Feedback'),
    VipDialog: () => import('@/views/company/vip/VipDialog'),
    TagsView: () => import('./TagsView')
  },
  data() {
    return {
      showServicerQrcode: false,
      name: localStorage.getItem('name'),
      funcList: [
        {
          id: '5',
          path: '/',
          name: '首页'
        },
        {
          id: '6',
          path: '/company/resume/jobApply',
          name: '待查看简历'
        },
        {
          id: '4',
          path: '/company/echarts/statistics',
          name: '招聘统计'
        },
        {
          id: '1',
          path: '/person/JobFairsList', //  person/JobFairsList  company/zph
          name: '招聘会'
        },
        {
          id: '0',
          path: '/company/chat/chatIndex',
          name: '聊天室'
        },
        {
          id: '3',
          path: '/company/account/setmeal',
          name: '账号权益'
        }
        // {
        // 	id: '2',
        // 	path: '',
        // 	name: '升级VIP'
        // }
      ],
      navWidth: this.$store.state.mainWidth
    };
  },
  computed: {
    companyLogo() {
      return this.$store.state.logo || window.global.avatarUrl + 'avatars.php?type=1&uid=' + localStorage.getItem('uid');
    },
    menueOpen() {
      return this.$store.state.menueOpen;
    },
    canClick() {
      return this.$store.state.companyStatus === '2' || !this.$store.state.isAudit;
    },
    needTagsView() {
      return true;
    }
  },
  mounted() {},
  methods: {
    reflashCur() {
      this.$router.go(0);
    },
    check_login(item) {
      if (!this.$store.state.isAudit) {
        this.$msg('请先完成企业认证后再操作', 'warning');
        return;
      }
      if (this.$store.state.companyStatus === '2') {
        this.$msg('企业账号处于暂停状态不能聊天', 'warning');
        return;
      }
      if (!this.$store.state.isPower) {
        this.$msg('请申请开通服务', 'warning');
        return;
      }
      this.$store.commit('setcurMenut', item.id);
      this.$router.push(item.path);
    },
    async logout1() {
      this.$confirm('此操作将退出系统, 是否继续?', '提示', {
        confirmButtonText: '退出',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$store.commit('clearLocation'); //移除浏览器登录缓存
          this.$store.commit('clearInter');
          this.$store.state.isLogin = false;
          this.$router.push('/');
          this.logout();
        })
        .catch(() => {});
    },
    myKefu() {
      this.showServicerQrcode = true;
    },
    myKefu1() {
      window.open('https://chatbot.weixin.qq.com/webapp/7COXQnmiGLXKoJ1lqXcErGQMp5PdJ1?robotName=%E6%B4%B9%E7%95%94%E4%BA%BA%E6%89%8D-%E5%9C%A8%E7%BA%BF%E6%9C%BA%E5%99%A8%E4%BA%BA');
    },
    funcClick(item) {
      // if (this.canClick && item.id !== '0') {
      // 	this.$msg('企业账号未审核通过，快去查看吧', 'warning')
      // 	return
      // }
      if (item.id === '2') {
        this.$refs.VipDialog._open();
      } else {
        this.$router.push(item.path);
      }
      this.$store.commit('setcurMenut', item.id);
    },
    feedback() {
      this.$refs.Feedback._open();
    },
    infoSet() {
      this.$router.push('/company/manage/baseInfo');
    }
  }
};
</script>

<style scoped>
.logo {
  background: #202329;
  cursor: default;
  width: 160px;
  height: 54px;
  background-image: url('../../assets/image/926.png');
  /* background-image: url('../../assets/image/logo@1x.png'); */
  background-repeat: no-repeat;
  background-position: center 8px;
  position: fixed;
  left: 0;
  top: 0;

  color: white;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.el-dropdown-menu__item a {
  color: #606266;
}

.top-nav {
  height: 54px;
  text-align: right;
  margin: 0 auto;
  box-sizing: content-box;
}

.top-nav .nav-item {
  display: inline-block;
}

.label-name a {
  color: #606266;
  font-size: 14px !important;
}

.top-nav .nav-item .label-name .active,
.top-nav .nav-item .label-name a:hover {
  color: #00a6a7;
}

.top-nav .nav-item .label-name {
  padding: 0 12px;
  cursor: pointer;
}

.top-nav img {
  width: 24px;
  height: 24px;
  border-radius: 100%;
  vertical-align: middle;
  margin: -3px 0 0 10px;
}

.page-inner {
  padding-left: 160px;
}

.logo-corner {
  position: fixed;
  width: 15px;
  height: 18px;
  left: 161px;
  top: -2px;
  z-index: 21;
  background: url('../../assets/image/member/sider-bottom-corner@2x.png') no-repeat 0 100%;
  transform: rotate(90deg);
  background-size: contain;
  margin: auto;
}
</style>
