<template>
  <div id="app">
    <router-view />
    <el-backtop :bottom="350">
      <div
        style="
           {
            height: 100%;
            width: 100%;
            background-color: #f2f5f6;
            box-shadow: 0 0 6px rgba(0, 0, 0, 0.12);
            text-align: center;
            line-height: 40px;
            color: #1989fa;
          }
        "
      >
        ↑
      </div>
      <Loading v-if="$store.state.showLoading"></Loading>
    </el-backtop>
  </div>
</template>

<script>
import api from '@/utils/api.js';
export default {
  data() {
    return {
      wids: {
        h: '',
        w: ''
      },
      metaData: [
        {
          name: 'viewport',
          content: 'width=device-width, initial-scale=0.32'
        }
      ]
    };
  },
  watch: {
    $route(to, from) {
      const _whitList = ['/chat/chatIndex', '/company/account/setmeal'];
      if (_whitList.indexOf(to.path) === -1) {
        this.$store.commit('setcurMenut', '');
      }
      //需要移动端适配的问题
      const _suoFang = [
        '/',
        '/home/realInfo',
        '/home/realDetail',
        // '/home/downLoad',
        '/user/login',
        '/user/register',
        '/person/JobFairsList',
        '/person/JobFairs',
        '/person/corp/corpDetail',
        '/person/positionIndex',
        '/person/about/5',
        '/hprcwadmin',
        '/hpapp',
        '/txwxface',
        '/hpappface',
        '/ayrcwFace',
        '/hprcFace',
        '/checkAvatar',
        '/person/about/4',
        '/person/corp',
        '/person/position/jobDetail'
      ]; //不缩放的路由页面
      let _router = '';
      if (to.path.substr(-1) === '/' && to.path !== '/') {
        _router = to.path.substr(0, to.path.length - 1);
      } else {
        _router = to.path;
      }
      if (_suoFang.indexOf(_router) > -1) {
        this.metaData = [
          {
            name: 'viewport',
            content: 'width=device-width,initial-scale=1.0'
            //width=device-width,initial-scale=1.0
            //width=950, user-scalable=yes, target-densitydpi=device-dpi
            //width=device-width,minimum-scale=1.0,maximum-scale=1.0,user-scalable=no
          }
        ];
      } else {
        this.metaData = [
          {
            name: 'viewport',
            content: 'width=device-width, initial-scale=0.38'
          }
        ];
      }
    }
  },
  metaInfo() {
    return {
      meta: this.metaData
    };
  },
  updated() {
    this.$store.commit('clearInter'); //清除定时器
    const that = this;
    if (!localStorage.getItem('uid') || !localStorage.getItem('utype') || !localStorage.getItem('hash')) return;
    const _timer = setInterval(() => {
      const _para = {
        uid: localStorage.getItem('uid'),
        utype: localStorage.getItem('utype'),
        pwd_hash: localStorage.getItem('hash')
      };
      this.$http
        .post(api.hash, _para)
        .then((res) => {
          // const vtime = localStorage.getItem('time');
          const text = res.success === '2' ? '检测到您非正常操作，请重新登录' : res.success === '0' ? '已退出登录，请重新登录' : '';
          if (res.success !== '1') {
            that.out(text);
          } else {
            if (res.system.title) {
              // this.$notify.info({
              // 	title: '消息',
              // 	duration: 0,
              // 	message: res.system.title
              // });
            }
          }
        })
        .catch((err) => {
          console.log(err);
          // that.out('hash接口异常，请联系后台管理员')
        });
    }, 5000);
    this.$store.commit('setTimerStauts', _timer);
  },
  mounted() {
    this.$store.state.height = window.innerHeight;
    this.$store.state.width = window.innerWidth;
    this.$store.state.mainWidth = (window.innerWidth - 160) / 1.2; //企业宽度
    this.$store.state.bodyWidth = window.innerWidth / 1.6; //求职者端
    this.$store.state.tableH = window.innerHeight - 350;
    window.addEventListener('resize', (e) => this.pageResize(e));
    if (process.env.NODE_ENV !== 'development') {
      //   this.stest(); //防止F12调试
      //   //禁止右键菜单
      //   document.oncontextmenu = function () {
      //     return false;
      //   };
      //   document.oncontextmenu = new Function('event.returnValue=false');
      //   // 禁止 F12 快捷键
      //   document.onkeydown =
      //     document.onkeyup =
      //     document.onkeypress =
      //       function () {
      //         if (window.event.keyCode == 123) {
      //           window.event.returnValue = false;
      //         }
      //       };
      //   document.onkeydown = () => {
      //     //禁用F12
      //     if (window.event && window.event.keyCode == 123) {
      //       return false;
      //       //禁用ctrl+shift+i,
      //     } else if (window.event.ctrlKey && window.event.shiftKey && window.event.keyCode == 73) {
      //       return false;
      //       //屏蔽Shift+F10
      //     } else if (window.event.shiftKey && window.event.keyCode == 121) {
      //       return false;
      //     }
      //   };
    }
  },
  methods: {
    stest() {
      (() => {
        function block() {
          if (window.outerHeight - window.innerHeight > 200 || window.outerWidth - window.innerWidth > 200) {
            document.body.innerHTML = '检测到非法调试,请关闭后刷新重试!';
          }
          setInterval(() => {
            (function () {
              return false;
            })
              ['constructor']('debugger')
              ['call']();
          }, 50);
        }
        try {
          block();
        } catch (err) {}
      })();
    },
    // 窗口变化
    pageResize() {
      this.wids.h = window.innerHeight;
      this.wids.w = window.innerWidth;
      this.$store.dispatch('windowSize', this.wids);
    },
    out(text) {
      this.$store.commit('clearLocation');
      this.$store.commit('clearInter');
      this.$store.commit('setLogin', false);
      this.$router.push('/');
      this.$store.commit('setPersonStateus', {
        isResumeReg: false //是否新注册简历
      });
      this.$confirm(text, '温馨提示', {
        confirmButtonText: '确定',
        showCancelButton: false,
        type: 'warning'
      })
        .then(() => {})
        .catch(() => {});
    }
  }
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  color: #2c3e50;
}

/* 绿色 */
.btnSub {
  background: #00bebd !important;
  border-color: #00bebd !important;
  color: white !important;
}

/* 金黄色 */
.btnGolden {
  background: #e6a23c !important;
  border-color: #e6a23c !important;
  color: white !important;
}

.warning {
  color: #e6a23c;
}

.el-notification {
  right: 42% !important;
}

body {
  margin: 0px;
  padding: 0px;
}

/* 默哀日灰色 */
/* html {
    -webkit-filter: grayscale(.95);
} */

.el-dialog {
  border-radius: 10px !important;
}

.flexElm {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flex {
  display: flex;
  align-items: center;
}

.flexStart {
  display: flex;
  align-items: flex-start;
}

.flexCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

.hideText {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #666666;
  max-width: 76%;
}

.hidden {
  display: -webkit-box !important;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical !important;
}

a,
a:hover {
  text-decoration: none;
}

li,
ol,
ul {
  list-style: none;
}

p {
  margin: 0px;
}

::-webkit-scrollbar {
  width: 0px;
  height: 8px;
  background-color: #ebeef5;
}

::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #ccc;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  background: rgba(255, 255, 255, 1);
}
@media screen and (max-width: 768px) {
  .el-backtop {
    bottom: 20% !important;
  }
}
</style>
