var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-sign",style:({ height: _vm.height + 'px' })},[_c('div',{staticClass:"sign-wrap-v2"},[_c('div',{staticClass:"sign-form sign-register"},[_c('div',{staticClass:"sign-slide-box hidden-xs-only"},[_vm._m(0),(!_vm.isBoss)?_c('ul',{staticClass:"geek-slide"},[_vm._m(1),_vm._m(2)]):_c('ul',{staticClass:"geek-slide"},[_vm._m(3),_vm._m(4),_vm._m(5)])]),_c('div',{staticClass:"sign-content"},[_c('div',{staticClass:"inner-box"},[_vm._t("default")],2),_vm._t("sao")],2)])]),_vm._m(6)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"logo",attrs:{"href":"/"}},[_c('img',{attrs:{"src":"https://www.hprcw.net/300.png"}}),_c('div',[_c('p',[_vm._v("找工作")]),_c('p',[_vm._v("上洹畔人才网谈")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('i',{staticClass:"icon"}),_c('span',[_vm._v("沟通")]),_c('span',[_vm._v("在线职位及时沟通")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('i',{staticClass:"icon"}),_c('span',[_vm._v("任性选")]),_c('span',[_vm._v("各大行业职位任你选")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('i',{staticClass:"icon"}),_c('span',[_vm._v("招聘效果好")]),_c('span',[_vm._v("与职场牛人在线开聊")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('i',{staticClass:"icon"}),_c('span',[_vm._v("更多在线牛人")]),_c('span',[_vm._v("入职速度快")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('i',{staticClass:"icon"}),_c('span',[_vm._v("人才匹配度高")]),_c('span',[_vm._v("获取更精准的牛人")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tel flex"},[_c('i',{staticClass:"el-icon-phone-outline",staticStyle:{"margin-right":"5px","font-size":"23px","font-weight":"bold"}}),_c('div',[_c('div',{staticStyle:{"margin-bottom":"5px","font-weight":"bold"}},[_vm._v("客服热线")]),_c('div',{staticStyle:{"font-size":"26px","font-weight":"bold"}},[_vm._v("400-0372-959")])])])
}]

export { render, staticRenderFns }