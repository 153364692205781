export const showLoading = (message) => {
	if (message === undefined) {
		message = '拼命请求中...'
	}
	window.$vue.$loadindInstance = window.$vue.$loading.service({
		fullscreen: true,
		text: message,
		spinner: 'el-icon-loading',
		background: 'rgba(0, 0, 0, 0.7)'
	})
}
export const notify = (message, type) => {
	let _text = ''
	if (type === 'warning') {
		_text = '温馨提示'
	}
	if (type === 'info') {
		_text = '提示'
	}
	if (type === 'error') {
		_text = '错误'
	}
	window.$vue.$notify({
		title: _text || '成功',
		duration: 3000,
		message: message || '接口异常，请稍后重试',
		type: type || 'success'
	});
}
export const isLogin = () => {
	if (!localStorage.getItem('uid')) {
		window.$vue.$router.push('/user/login')
		return false
	}
	return true
}
export const closeLoading = () => {
	if (window.$vue.$loadindInstance !== undefined) {
		window.$vue.$nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
			if (window.$vue.$loadindInstance != null) {
				window.$vue.$loadindInstance.close()
				window.$vue.$loadindInstance = undefined
			}
		})
	}
}