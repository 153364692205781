<template>
  <el-container>
    <el-header height="54px" style="padding: 0px; border-bottom: 2px solid #e5e5e5">
      <head-block />
    </el-header>
    <el-container class="company-main">
      <el-aside width="auto">
        <left-side></left-side>
        <div class="Tips_text23"></div>
      </el-aside>
      <el-main class="my_main">
        <app-main />
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import { login } from '@/utils/js/im.js';
import AppMain from './components/AppMain';
import HeadBlock from './components/HeadBlock';
import intro from '@/utils/introStep.js';
export default {
  name: 'layoutCompany',
  components: {
    AppMain,
    HeadBlock,
    LeftSide: () => import('./components/LeftSide')
  },
  data() {
    return {
      show: false,
      isAuto: false
    };
  },
  mounted() {
    let _this = this;
    if (localStorage.getItem('uid')) {
      _this.login();
      _this.getMessage();
      _this.getResumeNoSee(); //获取简历未读数
      _this.eventBus.$on('eventBusName1', function (data) {
        _this.getMessage();
      });
    }

    setTimeout(function () {
      if (localStorage.getItem('isFirst_1') != '2' && localStorage.getItem('uid') && localStorage.getItem('utype') == '1') {
        _this.initGuide();
      }
    }, 560);
  },
  methods: {
    initGuide() {
      intro
        .setOptions({
          steps: [
            {
              title: '新手引导 - 完善公司信息',
              element: '#infomation_1',
              intro: `完善公司基本信息是最基本的一步，为了不影响招聘效果，请尽快完善信息。`,
              position: 'right'
            },
            {
              title: '新手引导 - 证件上传',
              element: '#card_1',
              intro: '点击菜单，上传营业执照，输入营业执照编号以及身份信息提交审核，审核通过后方可进行后续的操作。',
              position: 'right'
            },
            {
              title: '新手引导 - 招聘统计',
              element: '#header_1_2',
              intro: '招聘统计中提供近期收到投递、下载简历、面试邀请量等图形分析。'
            },
            {
              title: '新手引导 - 账号权益',
              element: '#header_1_5',
              intro: '该页面中展示您的账号套餐信息用量等其他信息，也可以操作该账号暂停/启用状态。'
            }
          ]
        })
        .onbeforeexit(() => {
          //// 确认完毕之后执行的事件
          localStorage.setItem('isFirst_1', '2');
        })
        .start();
    },
    getMessage() {
      let _data = {
        method: 'list',
        utype: localStorage.getItem('utype'),
        uid: localStorage.getItem('uid')
      };
      this.$http.post('/msg_online', _data, false).then((res) => {
        let _num = 0;
        let _dataList = res.msg || [];
        _dataList.forEach((val) => {
          if (val.utype !== localStorage.getItem('utype')) {
            _num += parseInt(val.unreade);
          }
        });
        this.$store.commit('setMsg', _num);
      });
    },
    getResumeNoSee() {
      let _data = {
        method: 'recount_jobs',
        uid: localStorage.getItem('uid')
      };
      this.$http.post('/company_job', _data, false).then((res) => {
        let b = res.rdata[1] || 0; //待查看
        this.$store.commit('setResumeCount', parseInt(b));
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import url('../utils/introStep.css');

.company-main {
  height: calc(100vh - 54px);
}

.my_main {
  position: relative;
  background: url('../assets/image/bg-page-wrap.png') repeat-y top center;
  background-size: 100% auto;
}

.mask {
  background-color: rgb(0, 0, 0);
  opacity: 0.3;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
}

.Tips_text23 {
  position: fixed;
  width: 14px;
  height: 18px;
  left: 160px;
  bottom: 0;
  z-index: 30;
  background: url('../assets/image/member/sider-bottom-corner@2x.png') no-repeat 0 100%;
  background-size: contain;
}

.imgClose {
  width: fit-content;
  background-color: rgb(255, 255, 255);
  text-align: center;
  cursor: pointer;
  color: rgb(120, 74, 48);
  border-radius: 5px;
  border: 1px solid;
  padding: 3px 9px;
  font-size: 13px;
}

.caozuo {
  margin-top: 10px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
</style>
