<template>
	<div class="page_group">
		<div class="page_content">
			<el-pagination background :layout="layout||'prev, pager, next, jumper'" :total="total"
				:page-size="pageSize|| 10" :current-page="currentPage === undefined ? 1 : currentPage"
				@current-change="handleCurrentChange">
			</el-pagination>
		</div>
	</div>
</template>

<script>
	export default {
		name: "Pagination",
		props: ["total", "pageSize", "currentPage", "layout"],
		methods: {
			handleCurrentChange(val) {
				this.$emit("handleCurrentChange", val);
			}
		}
	};
</script>

<style lang="scss" scoped>
	.page_group {
		text-align: center;
		font-size: 0;
		padding: 25px 0;

		.page_content {
			display: inline-block;
		}
	}
</style>
