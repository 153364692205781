<template>
  <div class="page-sign" :style="{ height: height + 'px' }">
    <div class="sign-wrap-v2">
      <div class="sign-form sign-register">
        <div class="sign-slide-box hidden-xs-only">
          <a class="logo" href="/">
            <img src="https://www.hprcw.net/300.png" />
            <div>
              <p>找工作</p>
              <p>上洹畔人才网谈</p>
            </div>
          </a>
          <ul class="geek-slide" v-if="!isBoss">
            <li>
              <i class="icon"></i>
              <span>沟通</span>
              <span>在线职位及时沟通</span>
            </li>
            <li>
              <i class="icon"></i>
              <span>任性选</span>
              <span>各大行业职位任你选</span>
            </li>
          </ul>
          <ul class="geek-slide" v-else>
            <li>
              <i class="icon"></i>
              <span>招聘效果好</span>
              <span>与职场牛人在线开聊</span>
            </li>
            <li>
              <i class="icon"></i>
              <span>更多在线牛人</span>
              <span>入职速度快</span>
            </li>
            <li>
              <i class="icon"></i>
              <span>人才匹配度高</span>
              <span>获取更精准的牛人</span>
            </li>
          </ul>
        </div>
        <div class="sign-content">
          <div class="inner-box">
            <slot></slot>
          </div>
          <slot name="sao"></slot>
        </div>
      </div>
    </div>
    <div class="tel flex">
      <i
        class="el-icon-phone-outline"
        style="margin-right: 5px; font-size: 23px; font-weight: bold"
      ></i>
      <div>
        <div style="margin-bottom: 5px; font-weight: bold">客服热线</div>
        <div style="font-size: 26px; font-weight: bold">400-0372-959</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ComLogin',
  props: {
    height: {
      type: Number,
      default: 700
    },
    isBoss: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {};
  },
  methods: {}
};
</script>

<style scoped>
.page-sign {
  background: #5dd5c8 url('../assets/image/newbg.png') center bottom no-repeat;
}

.sign-wrap-v2 {
  width: 740px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.sign-wrap-v2 .sign-form {
  width: 100%;
  height: 580px;
  padding: 0;
  background: #fff;
  box-shadow: 0 6px 13px 0 rgb(0 0 0 / 10%);
  border-radius: 10px;
  position: relative;
}

.sign-slide-box {
  float: left;
  width: 240px;
  height: 100%;
  color: #8d92a1;
  background-color: #f8fcff;
  border-radius: 10px 0 0 10px;
}

.sign-slide-box ul {
  margin: 50px 0 0 0px;
}

.sign-slide-box li {
  margin-bottom: 50px;
}

.sign-slide-box li > span:first-of-type {
  line-height: 22px;
  color: #8d92a1;
  font-size: 16px;
  font-weight: 700;
}

.sign-slide-box li > span {
  display: block;
  margin-left: 50px;
}

.sign-slide-box li i {
  float: left;
  width: 25px;
  height: 42px;
  background: url('../assets/image/sign-slide.png') no-repeat;
  background-size: 100% auto;
}

.sign-slide-box li:nth-of-type(4n + 1) i {
  background-position: 0 10px;
}

.sign-slide-box li:nth-of-type(4n + 2) i {
  background-position: 0 -45px;
}

.sign-slide-box li > span:first-of-type {
  line-height: 22px;
  color: #8d92a1;
  font-size: 16px;
  font-weight: 700;
}

.sign-slide-box li > span:last-of-type {
  margin-top: 2px;
  line-height: 18px;
  color: #b0b4c1;
  font-size: 13px;
}

.sign-wrap-v2 .sign-content {
  position: relative;
  margin-left: 240px;
  height: 100%;
}

.sign-wrap-v2 .sign-register .inner-box {
  padding-top: 74px;
}

.sign-wrap-v2 .sign-form .inner-box {
  position: relative;
  width: 324px;
  margin: 0 auto;
}

.page-sign .tel {
  float: right;
  padding: 30px;
  line-height: 20px;
  color: #fff;
  vertical-align: middle;
  opacity: 0.7;
}

.btn-switch {
  position: absolute;
  z-index: 1005;
  width: 42px;
  height: 42px;
  background: no-repeat;
  background-size: 42px 84px;
  cursor: pointer;
  left: 6px;
  top: 6px;
}

.ewm-switch {
  background-image: url('../assets/image/btn-ewm.png');
}

.phone-switch {
  background-image: url('../assets/image/btn-phone.png');
}

.ewm-switch:hover {
  background-position: 0 -42px;
}

.phone-switch:hover {
  background-position: 0 -42px;
}

.sign-wrap-v2 h4 {
  margin: 0px 0 25px;
  line-height: 22px;
  color: #00c2b3;
  font-size: 16px;
  font-weight: 400;
}

.sign-miniapp {
  text-align: center;
}

.qrcodeimg-box {
  display: block;
  width: 200px;
  height: 200px;
  margin: 0 auto;
  position: relative;
}

.sign-miniapp .qrcodeimg-box img {
  width: 200px;
}

.invalid-box {
  display: none;
  width: 200px;
  height: 200px;
  margin: 0 auto;
  text-align: center;
  background: rgba(255, 255, 255, 0.9);
  position: absolute;
  left: 0;
  top: 0;
}

.sign-miniapp .sign-tip {
  margin-top: 30px;
  color: #61687c;
  line-height: 22px;
}

.sign-slide-box .logo {
  float: none;
  display: block;
  width: auto;
  height: 40px;
  margin: 88px 0 0 40px;
  padding: 0;
}

.sign-slide-box .logo img {
  width: 42px;
  height: 42px;
  float: left;
}

.sign-slide-box .logo div {
  float: left;
  position: relative;
  bottom: -1px;
  margin-left: 10px;
}

.sign-slide-box .logo p {
  color: #00c2b3;
  line-height: 20px;
  margin: auto;
}

@media screen and (max-width: 768px) {
  .sign-wrap-v2 .sign-content {
    margin-left: 0px !important;
  }

  .page-sign {
    height: 100vh !important;
  }

  .sign-wrap-v2 {
    width: 100% !important;
    margin: 0px !important;
    top: 0px !important;
    left: 0 !important;
    transform: none !important;
    height: 100vh;
  }

  .sign-wrap-v2 .sign-form {
    width: 100%;
    height: 100%;
    border-radius: 0px;
  }

  .page-sign .tel {
    float: none;
    color: #5dd5c8 !important;
    opacity: 0.5;
    position: absolute;
    bottom: 20px;
    width: 100%;
    padding: 0px;
    justify-content: center;
  }

  .logo a {
    display: block;
    width: 130px;
    height: 50px;
    background: url('../assets/image/926.png') 3px 7px no-repeat;
  }
}
</style>
