/*  创建：唐国庆
 *  时间：2021-11-1
 *  功能：Vue组件初始化封装，main中通 use 引用
 */
import ComLoginComponent from './ComLogin'
import zlDialogComponent from './BasicDialog'
import zlSelectComponent from './BasicSelect'
import Tag from './Tag'
import TitleCard from './common/TitleCard'
import SearchLan from './common/SearchLan'

const BasicComponent = {
	install(Vue) {
		Vue.component('TLogin', ComLoginComponent)
		Vue.component('BasicDialog', zlDialogComponent)
		Vue.component('BasicSelect', zlSelectComponent)
		Vue.component('Tag', Tag)
		Vue.component('TitleCard', TitleCard)
		Vue.component('SearchLan', SearchLan)
	}
}
export default BasicComponent
