import Layout from '@/layout/index';
import LayoutCompany from '@/layoutCompany/index';
import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

/**
 * 重写路由的push方法
 */
const routerPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return routerPush.call(this, location).catch((error) => error);
};

export const routes = [
  {
    path: '/',
    component: Layout,
    redirect: '/', //dashboard
    children: [
      {
        path: '/',
        name: 'Dashboard',
        component: () => import('../views/Home/Home'),
        meta: {
          title: '首页'
        }
      },
      {
        path: '/home/downLoad',
        name: 'APPDownLoad',
        component: () => import('@/views/Home/DownLoad'),
        meta: {
          title: 'APP下载'
        }
      },
      {
        path: '/person/personIndex',
        name: 'PersonIndex',
        component: () => import('@/views/person/PersonIndex'),
        meta: {
          title: '求职者-个人中心'
        }
      },
      {
        path: '/person/resume/index',
        name: 'ResumeIndex',
        component: () => import('@/views/person/resume/Index'),
        meta: {
          title: '求职者-简历中心'
        }
      },
      {
        path: '/person/chat/personChat',
        name: 'PersonChat',
        component: () => import('@/views/person/chat/PersonChat'),
        meta: {
          title: '求职者-聊天'
        }
      },
      {
        path: '/home/realInfo',
        name: 'RealInfo',
        component: () => import('@/views/Home/RealInfo'),
        meta: {
          title: '公告资讯'
        }
      },
      {
        path: '/home/realDetail',
        name: 'RealDetail',
        component: () => import('@/views/Home/components/RealDetail'),
        meta: {
          title: '资讯明细'
        }
      },
      {
        path: '/person/positionIndex',
        name: 'PositionIndex',
        component: () => import('../views/person/PositionIndex'),
        meta: {
          title: '职位搜索'
        }
      },
      {
        path: '/person/position/jobDetail',
        name: 'PersonJobDetail',
        component: () => import('../views/person/position/JobDetail'),
        meta: {
          title: '职位明细'
        }
      },
      {
        path: '/person/corp/corpDetail',
        name: 'CompanyDetail',
        component: () => import('../views/person/company/CompanyDetail'),
        meta: {
          title: '公司明细'
        }
      },
      {
        path: '/person/corp',
        name: 'CompanyIndex',
        component: () => import('../views/person/CompanyIndex'),
        meta: {
          title: '公司搜索'
        }
      },
      {
        path: '/person/account',
        name: 'PersonAccount',
        component: () => import('../views/person/account/Account'),
        meta: {
          title: '求职-账户设置'
        }
      },
      {
        path: '/person/account/privacy',
        name: 'Privacy',
        component: () => import('../views/person/account/Privacy'),
        meta: {
          title: '求职-隐私设置'
        }
      },
      {
        path: '/person/JobFairsList',
        name: 'JobFairsList',
        component: () => import('../views/person/JobFairsList'),
        meta: {
          title: '招聘会列表'
        }
      },
      {
        path: '/person/jobFairs',
        name: 'JobFairs',
        component: () => import('../views/person/JobFairs'),
        meta: {
          title: '招聘会明细',
          isMobile: navigator.userAgent.indexOf('Mobile') > -1 // 判断是否为手机设备
        }
      },
      {
        path: '/person/about/:id',
        name: 'About',
        component: () => import('@/views/person/about/index'),
        meta: {
          title: '关于我们'
        }
      },
      {
        path: '/person/words',
        name: 'PersonWords',
        component: () => import('@/views/person/Words'),
        meta: {
          title: '求职者常用语'
        }
      },
      {
        path: '/hqyl/springFlow',
        name: 'springFlow',
        component: () => import('@/views/hqyl/SpringFlow'),
        meta: {
          title: '洹泉涌流'
        }
      },
      {
        path: '/hqyl/policyDetail',
        name: 'PolicyDetail',
        component: () => import('@/views/hqyl/components/PolicyDetail'),
        meta: {
          title: '洹泉涌流明细'
        }
      }
    ]
  },
  {
    path: '/hpappface', //供APP中转webview跳转到app页面  【身份认证测试】测试使用的中专业
    name: 'HpAppFace',
    component: () => import('@/views/face/HpAppFace'),
    meta: {
      title: '人脸检测'
    }
  },
  {
    path: '/ayrcwFace', //供APP中转webview跳转到app页面  【安阳人才网】测试使用的中专业
    name: 'Ayrcw_face',
    component: () => import('@/views/face/Ayrcw_face'),
    meta: {
      title: '人脸检测'
    }
  },
  {
    path: '/hprcFace', //供APP中转webview跳转到app页面  【洹畔人才】测试使用的中专业
    name: 'Hprc_face',
    component: () => import('@/views/face/Hprc_face'),
    meta: {
      title: '人脸检测'
    }
  },
  {
    path: '/txwxface', //供腾讯云微信中转webview跳转到小程序页面
    name: 'TxWxFace',
    component: () => import('@/views/face/TxWxFace'),
    meta: {
      title: '人脸检测'
    }
  },
  {
    path: '/checkAvatar', //APP端检测头像是否是人像
    name: 'AvatarCheck',
    component: () => import('@/views/face/AvatarCheck'),
    meta: {
      title: '人像检测'
    }
  },
  {
    path: '/exportWord', //APP中转导出个人简历
    name: 'ExportWord',
    component: () => import('@/views/H5-APP/ExportWord'),
    meta: {
      title: 'APP导出简历'
    }
  },
  {
    path: '/hpapp',
    name: 'HpApp',
    component: () => import('@/views/user/DownApp'),
    meta: {
      title: 'APP下载'
    }
  },
  {
    path: '/hprcwadmin',
    component: () => import('@/views/hprcw/Index'),
    hidden: true
  },
  {
    path: '/user/register',
    component: () => import('@/views/user/Reg'),
    hidden: true,
    meta: {
      title: '注册'
    }
  },
  {
    path: '/user/manage',
    component: () => import('@/views/user/Manage'),
    hidden: true
  },
  {
    path: '/user/login',
    component: () => import('@/views/user/Login'),
    hidden: true,
    meta: {
      title: '登录'
    }
  },
  {
    path: '/company',
    component: LayoutCompany,
    redirect: '/company',
    children: [
      {
        path: '/company',
        name: 'company',
        component: () => import('../views/company/index'),
        meta: {
          title: '企业主页'
        }
      },
      {
        path: '/company/account',
        name: 'Account',
        component: () => import('../views/company/account/Account'),
        meta: {
          title: '账户管理'
        }
      },
      {
        path: '/company/chat/chatIndex',
        name: 'Chat',
        component: () => import('../views/chat/ChatIndex'),
        meta: {
          title: '在线聊天'
        }
      },
      {
        path: '/company/words',
        name: 'words',
        component: () => import('@/views/company/words/Words'),
        meta: {
          title: '企业常用语'
        }
      },
      {
        path: '/company/account/setmeal',
        name: 'Setmeal',
        component: () => import('../views/company/account/Setmeal'),
        meta: {
          title: '账号权益'
        }
      },
      {
        path: '/company/account/package',
        name: 'Package',
        component: () => import('../views/company/account/Package'),
        meta: {
          title: '新春套餐'
        }
      },
      {
        path: '/company/echarts/statistics',
        name: 'Statistics',
        component: () => import('../views/company/echarts/statistics'),
        meta: {
          title: '招聘统计'
        }
      },
      {
        path: '/company/manage/baseInfo',
        name: 'BaseInfo',
        component: () => import('../views/company/manage/BaseInfo'),
        meta: {
          title: '企业信息'
        }
      },
      {
        path: '/company/manage/Upload',
        name: 'upload',
        component: () => import('../views/company/manage/Upload'),
        meta: {
          title: '证件上传'
        }
      },
      {
        path: '/company/manage/Environment',
        name: 'environment',
        component: () => import('../views/company/manage/Environment'),
        meta: {
          title: '企业风采'
        }
      },
      {
        path: '/company/manage/news',
        name: 'News',
        component: () => import('../views/company/manage/News'),
        meta: {
          title: '企业新闻'
        }
      },
      {
        path: '/company/manage/business',
        name: 'business',
        component: () => import('../views/company/manage/Business'),
        meta: {
          title: '公司业务'
        }
      },
      {
        path: '/company/manage/video',
        name: 'Video',
        component: () => import('../views/company/manage/Video'),
        meta: {
          title: '企业视频'
        }
      },
      {
        path: '/company/zph',
        name: 'Zph',
        component: () => import('../views/company/Zph'),
        meta: {
          title: '招聘会'
        }
      },
      {
        path: '/company/job/jobList',
        name: 'JobList',
        component: () => import('../views/company/job/JobList'),
        meta: {
          title: '职位管理'
        }
      },
      {
        path: '/company/job/jobProxy',
        name: 'JobProxy',
        component: () => import('../views/company/job/JobProxy'),
        meta: {
          title: '岗位代招'
        }
      },
      {
        path: '/company/jobEdit/:id', //修改职位
        name: 'JobEdit',
        component: () => import('../views/company/job/JobEdit'),
        meta: {
          title: '编辑职位'
        }
      },
      {
        path: '/company/job/jobDetail', //职位预览
        name: 'JobDetail',
        component: () => import('../views/company/job/JobDetail'),
        meta: {
          title: '职位预览'
        }
      },
      {
        path: '/company/jobadd', //新增职位
        name: 'JobAdd',
        component: () => import('../views/company/job/JobAdd'),
        meta: {
          title: '发布职位'
        }
      },
      {
        path: '/company/job/jobList_lhyg', //灵活
        name: 'JobList_lhyg',
        component: () => import('../views/company/job/JobList_lhyg'),
        meta: {
          title: '灵活用工'
        }
      },
      {
        path: '/company/job/jobEdit_lhyg/:id', //灵活编辑
        name: 'JobEdit_lhyg',
        component: () => import('../views/company/job/JobEdit_lhyg'),
        meta: {
          title: '编辑灵活用工'
        }
      },
      {
        path: '/company/job/jobAdd_lhyg', //灵活新增
        name: 'JobAdd_lhyg',
        component: () => import('../views/company/job/JobAdd_lhyg'),
        meta: {
          title: '发布灵活用工'
        }
      },
      {
        path: '/company/recommend', //智能推荐
        name: 'Recommend',
        component: () => import('@/views/company/Recommend'),
        meta: {
          title: '智能推荐'
        }
      },
      {
        path: '/company/person/search', //搜索人才
        name: 'Search',
        component: () => import('../views/company/person/Search'),
        meta: {
          title: '人才库'
        }
      },
      {
        path: '/company/resume/jobApply', //职位申请
        name: 'JobApply',
        component: () => import('../views/company/resume/JobApply'),
        meta: {
          title: '职位申请'
        }
      },
      {
        path: '/company/resume/downLoad', //我的下载
        name: 'DownLoad',
        component: () => import('../views/company/resume/DownLoad'),
        meta: {
          title: '我的下载'
        }
      },
      {
        path: '/company/resume/interview', //面试邀请
        name: 'Interview',
        component: () => import('../views/company/resume/Interview'),
        meta: {
          title: '面试邀请'
        }
      },
      {
        path: '/company/resume/fav', //我的收藏
        name: 'Fav',
        component: () => import('../views/company/resume/Fav'),
        meta: {
          title: '我的收藏'
        }
      },
      {
        path: '/company/resume/browsing', //浏览记录
        name: 'Browsing',
        component: () => import('../views/company/resume/Browsing'),
        meta: {
          title: '浏览过的人才'
        }
      },
      {
        path: '/company/zphDetail', //招聘会明细
        name: 'ZphDetail',
        component: () => import('../views/company/vip/ZphDetail'),
        meta: {
          title: '浏览过的人才'
        }
      }
    ]
  }
];

const router = new VueRouter({
  mode: 'history', // 需要服务端支持
  routes,
  scrollBehavior() {
    return {
      x: 0,
      y: 0
    };
  }
});

function getRoutesList(routes, pre) {
  return routes.reduce((array, route) => {
    const path = `${pre}${'/'}${route.path}`;
    if (route.path !== '*') {
      array.push(path);
    }

    if (route.children) {
      array.push(...getRoutesList(route.children, `${pre}`));
    }

    return array;
  }, []);
}

function getRoutesXML() {
  const list = getRoutesList(router.options.routes, 'http://www.hprcw.net')
    .map((route) => `<url><loc>${route}</loc></url>`)
    .join('\r\n');
  return `<urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xsi:schemaLocation="http://www.sitemaps.org/schemas/sitemap/0.9 http://www.sitemaps.org/schemas/sitemap/0.9/sitemap.xsd">
    ${list}
  </urlset>`;
}

// console.log(getRoutesXML())

export default router;
