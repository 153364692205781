/*
功能：单选、复选组件
*/
<template>
	<el-select v-model="code" :multiple="multiple" :multiple-limit="multipleLimit" :collapse-tags="collapseTags"
		filterable :clearable="clearable" :disabled="disabled" :placeholder="placeholder" :size="size"
		style="width: 100%" @change="handleChange">
		<el-option v-for="(item,index) in dataItem" :key="index" :label="item[columns[1]]" :value="item[columns[0]]"
			:disabled="item.disabled">
			<span :style="spanStyle">{{ item[columns[1]] }}</span>
			<span v-show="showId" style="float: left; color: #8492a6; font-size: 13px">{{ item[columns[2]] }}</span>
		</el-option>
	</el-select>
</template>

<script>
	export default {
		name: 'ZlSelect',
		props: {
			value: {
				// 值
				type: [Array, Number, String],
				default () {
					return ''
				}
			},
			clearable: {
				// 是否可清空
				type: Boolean,
				default: true
			},
			multiple: {
				// 是否多选
				type: Boolean,
				default: false
			},
			multipleLimit: {
				// 多选数量限制
				type: Number,
				default: 0
			},
			collapseTags: {
				// 是否多选数字累加
				type: Boolean,
				default: false
			},
			options: {
				// 下拉数据
				type: Array,
				default: function() {
					return []
				}
			},
			isDefault: {
				// 是否默认选中第一个
				type: Boolean,
				default: false
			},
			returnDataRow: {
				// 是否返回数据行
				type: Boolean,
				default: true
			},
			size: {
				// 尺寸
				type: String,
				default: 'medium'
			},
			placeholder: {
				type: String,
				default: ''
			},
			columns: {
				// 绑定列
				type: Array,
				default () {
					return ['id', 'name']
				}
			},
			parameters: {
				// 方法参数
				type: Object,
				default () {
					return {}
				}
			},
			autoQuery: {
				// 自动刷新
				type: Boolean,
				default () {
					return true
				}
			},
			query: {
				// 查询数据方法
				type: Function,
				default: null
			},
			showId: {
				// 是否显示value值
				type: Boolean,
				default: false
			},
			disabled: {
				// 是否可以编辑
				type: Boolean,
				default: false
			}
		},
		data() {
			if (this.multiple) {
				return {
					code: [],
					dataItem: []
				}
			} else {
				return {
					code: '',
					dataItem: []
				}
			}
		},
		computed: {
			spanStyle() {
				// span样式显示控制
				return this.showId ? 'float: right' : 'float: left'
			}
		},
		watch: {
			value(val) {
				// 监听值并赋值
				this.code = val
			},
			options(val) {
				this.onQuery(val)
			}
		},
		created() {
			if (this.autoQuery) {
				this.onQuery()
			}
		},
		methods: {
			// 选择改变事件
			handleChange(value) {
				this.changeEvent(value, false)
			},
			// 获取选中行数据
			changeEvent(value, init) {
				const data = {
					init,
					index: -1,
					item: {}
				}
				if (this.returnDataRow) {
					data.index = this.dataItem.findIndex(
						item => item[this.columns[0]] === value
					)
					if (data.index >= 0) {
						data.item = this.dataItem[data.index]
					}
				}
				this.$emit('input', value, this.dataItem, data)
			},
			// 查询数据
			onQuery() {
				if (this.options && this.options.length > 0) {
					this.dataItem = this.options
					this.setDefaultVal()
				} else {
					this.queryData()
				}
			},
			// 接口查询数据
			queryData() {
				if (this.query) {
					this.query(this.parameters).then(request => {
						if (request.code === 200) {
							this.dataItem = request.data
							this.setDefaultVal()
						}
					})
				} else {
					this.dataItem = this.options
				}
			},
			// 使用传入默认数据
			setDefaultVal() {
				if (this.isDefault) {
					if (this.dataItem.length > 0) {
						if (this.multiple) {
							this.code.push(this.dataItem[0][this.columns[0]])
						} else {
							this.code = this.dataItem[0][this.columns[0]]
						}
					}
				} else {
					this.code = this.value
				}
				this.changeEvent(this.code, true)
			}
		}
	}
</script>
