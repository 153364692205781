/* 功能：编辑子窗体组件 */
<template>
  <el-dialog
    :title="caption"
    :width="width"
    :visible.sync="show"
    :close-on-click-modal="clickClose"
    :close-on-press-escape="false"
    :show-close="showClose"
    append-to-body
    @before-close="handleClose"
    @open="handleOpen"
    @opened="handleOpened"
  >
    <el-form
      ref="dataForm"
      :model="dataForm"
      :rules="rules"
      :style="Height"
      :label-width="labelWidth"
      :size="size"
      @submit.native.prevent
    >
      <slot />
    </el-form>
    <slot name="other" />
    <div slot="footer" class="dialog-footer" v-if="showFooter">
      <slot name="btn" />
      <el-button :size="size" @click.native="onClose">关闭</el-button>
      <el-button
        v-show="showBtn"
        class="btnSub"
        :size="size"
        type="primary"
        :btn-disabled="btnDisabled"
        @click.native="onSave('dataForm')"
      >
        {{ btnLabel }}
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
// import { checkFormData } from '@/utils/common' // 引入Api接口方法
export default {
  name: 'ZlDialog',
  props: {
    showBtn: {
      // 确认按钮是否显示
      type: Boolean,
      default: true
    },
    showClose: {
      // 是否显示关闭按钮
      type: Boolean,
      default: true
    },
    showFooter: {
      // 是否显示底部
      type: Boolean,
      default: true
    },
    btnDisabled: {
      // 确认按钮是否可点击
      type: Boolean,
      default: false
    },
    labelWidth: {
      // label宽度
      type: String,
      default: '100px'
    },
    btnLabel: {
      type: String,
      default: '保存'
    },
    clickClose: {
      //点击其他区域关闭
      type: Boolean,
      default: false
    },
    isEdit: {
      // 是否修改
      type: Boolean,
      default: false
    },
    showTitleFuc: {
      // 是否标题的功能描述
      type: Boolean,
      default: false
    },
    title: {
      // 标题
      type: String,
      default: '标题'
    },
    dataFormName: {
      // 父窗体数据对象名称
      type: String,
      default: 'dataForm'
    },
    width: {
      // 弹窗宽度
      type: String,
      default: '40%'
    },
    size: {
      // 尺寸大小
      type: String,
      default: 'small'
    },
    items: {
      // 弹窗form数据
      type: Object,
      default: null
    },
    formData: {
      // 弹窗form数据
      type: Object,
      default: null
    },
    rowData: {
      // 编辑时行数据
      type: Object,
      default: null
    },
    queryFunc: {
      // 查询方法
      type: Function,
      default: null
    },
    saveFunc: {
      // 保存方法
      type: Function,
      default: null
    },
    height: {
      // form高度
      type: String,
      default: '400px'
    },
    rules: {
      // 校验规则
      type: Object,
      default: null
    }
  },
  data() {
    return {
      dataForm: {},
      show: false
    };
  },
  computed: {
    caption() {
      // 标题
      return this.title + ' ' + (this.showTitleFuc ? (this.isEdit ? '编辑' : '新增') : '');
    },
    Height() {
      // form高度
      return 'height:' + this.height;
    }
  },
  methods: {
    // 打开弹窗
    open() {
      this.show = true;
    },
    // 打开前
    handleOpen() {
      const data = this.items ? this.items : this.formData;
      for (const item in data) {
        this.$set(this.dataForm, item, data[item]);
      }
    },
    // 打开后
    handleOpened() {
      const that = this;
      if (that.show) {
        if (that.$parent.$options.data()[this.dataFormName] !== undefined) {
          that.dataForm = that.$parent.$options.data()[this.dataFormName];
        }
        that.$refs['dataForm'].resetFields();
        if (that.isEdit) {
          this.editDataForm(that);
        } else {
          this.addDataForm(that);
        }
      }
    },
    // 新增时数据清空处理初始值
    addDataForm(that) {
      that.$emit('update:items', that.dataForm);
      that.$emit('update:formData', that.dataForm);
      that.$emit('opened', that.isEdit, this.dataForm);
    },
    // 修改
    editDataForm(that) {
      if (that.queryFunc) {
        that.$refs['dataForm'].resetFields();
        that.queryFunc(that.rowData).then((res) => {
          if (res.code === 200) {
            for (const item in that.dataForm) {
              if (!res.data[item] && res.data[item] !== 0) {
                that.$set(that.dataForm, item, '');
              } else {
                if (typeof res.data[item] === 'object' && res.data[item] != null) {
                  if (res.data[item] instanceof Array) {
                    var arr = [];
                    for (let i = 0; i < res.data[item].length; i++) {
                      arr.push(res.data[item][i]);
                    }
                    this.$emit('initDetail', arr, item);
                  } else {
                    this.$emit('initDetail', res.data[item], item);
                  }
                } else {
                  that.$set(that.dataForm, item, res.data[item]);
                }
              }
            }
            that.$emit('update:items', that.dataForm);
            that.$emit('update:formData', that.dataForm);
            that.$emit('opened', that.isEdit, this.dataForm);
          }
        });
      } else {
        this.$nextTick(() => {
          for (const item in that.dataForm) {
            that.$set(that.dataForm, item, that.rowData[item] ? that.rowData[item] : '');
          }
          that.$emit('update:items', that.dataForm);
          that.$emit('update:formData', that.dataForm);
          that.$emit('opened', that.isEdit, this.dataForm);
        });
      }
    },
    // 保存事件
    onSave(formName) {
      // handleSave 保存触发
      this.checkTrim(this.dataForm);
      this.$refs[formName].validate((valid, obj) => {
        if (valid) {
          this.dataForm['setType'] = this.isEdit ? 2 : 1;
          this.saveData();
        } else {
          let msg = '';
          for (const key in obj) {
            msg += '【' + obj[key][0].message + '】';
          }
          this.$msg.warning('校验未通过 - ' + msg);
        }
      });
    },
    // 去除空格
    checkTrim(formName) {
      const that = this;
      for (const item in formName) {
        switch (typeof formName[item]) {
          case 'string':
            that.$set(formName, item, formName[item].trim());
            break;
          default:
            break;
        }
      }
    },
    // 保存方法
    saveData() {
      if (this.saveFunc) {
        this.setGuidId();
        this.saveFunc(this.dataForm).then((res) => {
          if (res.code === 200) {
            this.$msg.sucess(this.title + '保存成功');
            this.$emit('dialogSave', this.dataForm, res.id);
          }
        });
      } else {
        this.$emit('dialogSave', this.dataForm);
      }
    },
    // GuidID赋值
    setGuidId() {
      if (!this.isEdit) {
        this.dataForm['id'] = this.$Guid.getUUID();
      }
    },
    // 清除校验
    clearValidate(fields) {
      if (!fields) {
        this.$refs['dataForm'].clearValidate();
      } else {
        this.$refs['dataForm'].clearValidate(fields);
      }
    },
    // 关闭弹窗
    handleClose(done) {
      done();
    },
    // 关闭弹窗
    onClose() {
      this.show = false;
      this.$emit('closeDialog', this.dataForm);
    },
    // 校验数据重复
    checkCode(rule, value, callback, option, parameter) {
      console.log(rule, value, callback, option, parameter);
      // checkFormData(rule, value, callback, option, parameter, this.dataForm)
    }
  }
};
</script>
<style lang="scss">
.el-dialog {
  display: flex;
  flex-direction: column;
  margin: 0 !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: calc(100% - 30px);
  max-width: calc(100% - 30px);
}

.el-dialog__body {
  padding: 10px 15px;
}

.el-dialog__header {
  text-align: left;
}

.el-dialog__footer {
  text-align: right;
}

.el-dialog .el-dialog__body {
  flex: 1;
  overflow: auto;
}
</style>
