/**
 * Created by PanJiaChen on 16/11/18.
 */

/**
 * @param {string} path
 * @returns {Boolean}
 */
export function isExternal(path) {
	return /^(https?:|mailto:|tel:)/.test(path)
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validUsername(str) {
	const sing = str || ''
	return sing
}

//去除空格
export function removeSpaces(json) {
	const newJson = {}
	for (let key in json) {
		if (typeof json[key] == 'string') {
			newJson[key] = json[key].trim()
		} else {
			newJson[key] = json[key]
		}
	}
	return newJson
}

export function validateMobilePhone(value) {
	if (value !== '') {
		var reg = /^1[3456789]\d{9}$/
		if (!reg.test(value)) {
			return '请输入有效的手机号码'
		}
	}
}
// 验证手机号不可为空
export function validatePhone(value) {
	var reg = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
	if ((!reg.test(value)) && value !== '') {
		return '请输入有效的手机号码'
	}
	return ''
}
//正则表达式
export function expr() {
	return {
        name: /^[\u4e00-\u9fa5]{2,15}$/i, //名称
        cludZh:/[\u4E00-\u9FA5]/g,//是否包含中文
		name1: /^[\u0391-\uFFE5A-Za-z]+$/, //汉字或字符
		number: /^[0-9]*$/, //数字
		sex: /.*(男|女).*/, //性别歧视限制
		key: /.*(诚聘).*/, //敏感字眼
		job_content: /.*(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}|[0-9]{7,8}.*/g, //发布职位限制
		special: /[(\ )(\~)(\!)(\@)(\#)(\$)(\%)(\^)(\&)(\*)(\)(\_)(\+)(\=)(\[)(\])(\{)(\})(\|)(\\)(\;)(\:)]+/, //特殊符号
		tel: /^(((\d{3,4}-)?[0-9]{7,8})|(1(3|4|5|6|7|8|9)\d{9}))$/, //固定电话
		phone: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/, //手机号
		email: /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/ //邮箱
	}
}
// 验证固话
export function validateTelphone(rule, value, callback) {
	var reg = /^\d{3,4}-?\d{7,9}$/
	if (value === '' || value === undefined || value === null) {
		callback()
	} else {
		if ((!reg.test(value)) && value !== '') {
			callback(new Error('座机格式:区号-座机号码'))
		} else {
			callback()
		}
	}
}
// 验证QQ
export function validateIsQQ(rule, value, callback) {
	var reg = /^[1-9][0-9]{4,}$/
	if (value === '' || value === undefined || value === null) {
		callback()
	} else {
		if ((!reg.test(value)) && value !== '') {
			callback(new Error('请输入正确的QQ号码'))
		} else {
			callback()
		}
	}
}

// 验证微信
export function validateWeixin(rule, value, callback) {
	var reg = /^[a-zA-Z][a-zA-Z0-9_-]{5,19}$/
	if (value === '' || value === undefined || value === null) {
		callback()
	} else {
		if ((!reg.test(value)) && value !== '') {
			callback(new Error('请输入正确的微信号码'))
		} else {
			callback()
		}
	}
}
// 身份证号验证
export function validateIdCard(rule, value, callback) {
	const reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/
	if (value === '' || value === undefined || value === null) {
		callback()
	} else {
		if ((!reg.test(value)) && value !== '') {
			callback(new Error('请输入正确的身份证号码'))
		} else {
			callback()
		}
	}
}
// 工龄（最多一位小数）
export function validateWorkYears(rule, value, callback) {
	const reg = /^[0-9]+([.]{1}[0-9]{1})?$/
	if (value === '' || value === undefined || value === null) {
		callback(new Error('请输入工龄'))
	} else {
		if ((!reg.test(value)) && value !== '') {
			callback(new Error('请输入有效数字，最多一位小数'))
		} else {
			callback()
		}
	}
}
// 年龄
export function validateAge(rule, value, callback) {
	const reg = /^[0-9]*[1-9][0-9]*$/
	if (value === '' || value === undefined || value === null) {
		callback()
	} else {
		if ((!reg.test(value)) && value !== '') {
			callback(new Error('请输入正确年龄'))
		} else {
			callback()
		}
	}
}
// 正数（最多一位小数）
export function validateDecimalsOne(rule, value, callback) {
	const reg = /^[0-9]+([.]{1}[0-9]{1})?$/
	if (value === '' || value === undefined || value === null) {
		callback()
	} else {
		if ((!reg.test(value)) && value !== '') {
			callback(new Error('请输入有效数字，最多一位小数'))
		} else {
			callback()
		}
	}
}
// 银行卡验证
export function validateBankCard(rule, value, callback) {
	const reg = /^([1-9]{1})(\d{14}|\d{15}|\d{18})$/
	if (value === '' || value === undefined || value === null) {
		callback()
	} else {
		if ((!reg.test(value)) && value !== '') {
			callback(new Error('格式错误，请重输入'))
		} else {
			callback()
		}
	}
}
// 数字类型
export function validateNumber(rule, value, callback) {
	const reg = /^[0-9]*[1-9][0-9]*$/
	if (value === '' || value === undefined || value === null) {
		callback()
	} else {
		if ((!reg.test(value)) && value !== '') {
			callback(new Error('格式错误，请重输入'))
		} else {
			callback()
		}
	}
}
// 验证由数字和26个英文字母组成的字符串
export function validateA_Za_z0_9(rule, value, callback) {
	const reg = /^[A-Za-z0-9]+$/
	if (value === '' || value === undefined || value === null) {
		callback()
	} else {
		if ((!reg.test(value)) && value !== '') {
			callback(new Error('格式错误，请重输入'))
		} else {
			callback()
		}
	}
}
// 验证由下划线，数字和26个英文字母组成的字符串
export function validate_W(rule, value, callback) {
	const reg = /^\w+$/
	if (value === '' || value === undefined || value === null) {
		callback()
	} else {
		if ((!reg.test(value)) && value !== '') {
			callback(new Error('格式错误，请重输入'))
		} else {
			callback()
		}
	}
}
// 特殊字符
export function validateContacts(value) {
	if (value !== '') {
		var reg = /^[\u4e00-\u9fa5_a-zA-Z0-9]+$/
		if (!reg.test(value)) {
			return false
		}
		return true
	}
}
// 正整数
export function validateIsInteger(value) {
	if (value !== '') {
		var reg = /^[0-9]*[1-9][0-9]*$/
		if (!reg.test(value)) {
			return '格式错误'
		}
	}
}
// 0-1的小数
export function validateIsDecimal(value) {
	if (value !== '') {
		var reg = /^(([1-9]\d*(\.\d{1,2})?)|(0\.\d{1,2}))$/
		if (!reg.test(value)) {
			return '请输入有效数字，最多两位小数'
		} else if (value < 0 || value > 1) {
			return '请输入[0,1]之间的数字'
		}
	}
}
// 正数（最多两位小数）
export function validateDecimalsTwo(value) {
	if (value !== '') {
		var reg = /^(([1-9]\d*(\.\d{1,2})?)|(0\.\d{1,2}))$/
		if (!reg.test(value)) {
			return '请输入有效数字，最多两位小数'
		}
	}
}
// 数字
export function validatePositive(value) {
	if (value !== '') {
		var reg = /^\d+$|^\d+[.]?\d+$/
		if (!reg.test(value)) {
			return '请输入数字'
		}
	}
}

export function dateFtt(fmt, date) {
	var o = {
		'M+': date.getMonth() + 1, // 月份
		'd+': date.getDate(), // 日
		'h+': date.getHours(), // 小时
		'm+': date.getMinutes(), // 分
		's+': date.getSeconds(), // 秒
		'q+': Math.floor((date.getMonth() + 3) / 3), // 季度
		'S': date.getMilliseconds() // 毫秒
	}
	if (/(y+)/.test(fmt)) {
		fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
	}
	for (var k in o) {
		if (new RegExp('(' + k + ')').test(fmt)) {
			fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (('00' + o[k]).substr(('' + o[k]).length)))
		}
	}
	return fmt
}
// 根据id获取元素下标
export function dorwes(arr, id) {
	return arr.map(item => item.id).indexOf(id)
}
// 获取元素的下标
export function getArrayIndex(arr, obj) {
	var i = arr.length
	while (i--) {
		if (arr[i] === obj) {
			return i
		}
	}
	return -1
}
// 获取文件后缀名
export function extname(filename) {
	if (!filename || typeof filename !== 'string') {
		return false
	}
	const a = filename.split('').reverse().join('')
	const b = a.substring(0, a.search(/\./)).split('').reverse().join('')
	return b
}
// 当前日期
export function dateline() {
	const date = new Date()
	// const seperator1 = '-'
	const year = date.getFullYear()
	let month = date.getMonth() + 1
	let strDate = date.getDate()
	if (month >= 1 && month <= 9) {
		month = '0' + month
	}
	if (strDate >= 0 && strDate <= 9) {
		strDate = '0' + strDate
	}

	const currentdate = String(year) + '-' + String(month) + '-' + String(strDate)
	return currentdate
}
/**
 * 
 * @param {*} arry 
 * @returns
 */
export function arryFormatTime(arry) {
    if (!Array.isArray(arry)) return arry
    arry.forEach((item, index) => {
        //判断不包含中文则转换
        if (!expr().cludZh.test(item.start) && !expr().cludZh.test(item.endtime)) {
            item['start'] = parseTime(item.start, '{y}-{m}')
            item['endtime'] = parseTime(item.endtime, '{y}-{m}')
        }
    })
    return arry
}
// 当前月份
export function dateMonth() {
	const date = new Date()
	const year = date.getFullYear()
	let month = date.getMonth() + 1
	let strDate = date.getDate()
	if (month >= 1 && month <= 9) {
		month = '0' + month
	}
	if (strDate >= 0 && strDate <= 9) {
		strDate = '0' + strDate
	}

	const currentdate = String(year) + '-' + String(month)
	return currentdate
}
// 日期+时间戳
export function getDateTime() {
	const date = new Date()
	// const seperator = '-'
	const year = date.getFullYear()
	let month = date.getMonth() + 1
	let strDate = date.getDate()
	if (month >= 1 && month <= 9) {
		month = '0' + month
	}
	if (strDate >= 0 && strDate <= 9) {
		strDate = '0' + strDate
	}

	const currentdate = String(year) + String(month) + String(strDate) + date.getTime()
	return currentdate
}
// 截取文件后缀名
export function getFileFormat(res) {
	const fileName = res.lastIndexOf('.') // 取到文件名开始到最后一个点的长度
	const fileNameLength = res.length // 取到文件名长度
	const fileFormat = res.substring(fileName + 1, fileNameLength) // 截
	console.log(fileFormat)
	return fileFormat
}
// 获取星期几
export function getWeek(dateString) {
	if (dateString) {
		const dateArray = dateString.split('-')
		const date = new Date(dateArray[0], parseInt(dateArray[1] - 1), dateArray[2])
		return {
			week: '星期' + '日一二三四五六'.charAt(date.getDay()),
			ints: date.getDay()
		}
	} else {
		return ''
	}
}
// 生成随机字段
export function getUuid() {
	var s = []
	var hexDigits = '0123456789abcdef'
	for (var i = 0; i < 36; i++) {
		s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1)
	}
	s[14] = '4' // bits 12-15 of the time_hi_and_version field to 0010
	s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1) // bits 6-7 of the clock_seq_hi_and_reserved to 01
	s[8] = s[13] = s[18] = s[23] = '-'
	var uuid = s.join('')
	return uuid
}
//对象转 拼接参数
export function getExportUrl2(query){
    let expurl = '?';
      let queryStr = '';
      const keys = Object.keys(query);
      for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
        queryStr += key + '=' + query[key];

        if (i !== keys.length - 1) {
          queryStr += '&';
        }
      }
      expurl += queryStr;
      return expurl;
}
// 获取当前日期时间
export function getTime() {
	const yy = new Date().getFullYear()
	const mm = new Date().getMonth() + 1
	const dd = new Date().getDate()
	const hh = new Date().getHours()
	const mf = new Date().getMinutes() < 10 ? '0' + new Date().getMinutes() : new Date().getMinutes()
	const ss = new Date().getSeconds() < 10 ? '0' + new Date().getSeconds() : new Date().getSeconds()
	const gettime = yy + '-' + mm + '-' + dd + ' ' + hh + ':' + mf + ':' + ss
	return gettime
}
// date 代表指定的日期，格式：2018-09-27
// day 传-1表始前一天，传1表始后一天
// JS获取指定日期的前一天，后一天
export function getNextDate(date, day) {
	const dd = new Date(date)
	dd.setDate(dd.getDate() + day)
	const y = dd.getFullYear()
	const m = dd.getMonth() + 1 < 10 ? '0' + (dd.getMonth() + 1) : dd.getMonth() + 1
	const d = dd.getDate() < 10 ? '0' + dd.getDate() : dd.getDate()
	return y + '-' + m + '-' + d
}
// 根据出生日期计算年龄
export function getAge(strAge) {
	var birArr = strAge.split('-')
	var birYear = birArr[0]
	var birMonth = birArr[1]
	var birDay = birArr[2]
	d = new Date()
	var nowYear = d.getFullYear()
	var nowMonth = d.getMonth() + 1 // 记得加1
	var nowDay = d.getDate()
	var returnAge

	if (birArr == null) {
		return false
	}
	var d = new Date(birYear, birMonth - 1, birDay)
	if (d.getFullYear() === birYear && (d.getMonth() + 1) === birMonth && d.getDate() === birDay) {
		if (nowYear === birYear) {
			returnAge = 0 //
		} else {
			var ageDiff = nowYear - birYear //
			if (ageDiff > 0) {
				if (nowMonth === birMonth) {
					var dayDiff = nowDay - birDay //
					if (dayDiff < 0) {
						returnAge = ageDiff - 1
					} else {
						returnAge = ageDiff
					}
				} else {
					var monthDiff = nowMonth - birMonth //
					if (monthDiff < 0) {
						returnAge = ageDiff - 1
					} else {
						returnAge = ageDiff
					}
				}
			} else {
				return '出生日期晚于今天，数据有误' // 返回-1 表示出生日期输入错误 晚于今天
			}
		}
		return returnAge
	} else {
		return ('输入的日期格式错误！')
	}
}
/**
 * Parse the time to string
 * @param {(Object|string|number)} time
 * @param {string} cFormat
 * @returns {string | null}
 */
export function parseTime(time, cFormat) {
	if (arguments.length === 0) {
		return null
	}
	const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
	let date
	if (typeof time === 'object') {
		date = time
	} else {
		if (typeof time === 'string' && /^[0-9]+$/.test(time)) {
			time = parseInt(time)
		}
		if (typeof time === 'number' && time.toString().length <= 10) {
			time = time * 1000
		}
		date = new Date(time)
	}
	const formatObj = {
		y: date.getFullYear(),
		m: date.getMonth() + 1,
		d: date.getDate(),
		h: date.getHours(),
		i: date.getMinutes(),
		s: date.getSeconds(),
		a: date.getDay()
	}
	const time_str = format.replace(/{([ymdhisa])+}/g, (result, key) => {
		const value = formatObj[key]
		// Note: getDay() returns 0 on Sunday
		if (key === 'a') {
			return ['日', '一', '二', '三', '四', '五', '六'][value]
		}
		return value.toString().padStart(2, '0')
	})
	return time_str
}
/**
 * @param {number} time
 * @param {string} option
 * @returns {string}
 */
export function formatTime(time, option, detailed) {
	if (('' + time).length <= 10) {
		time = parseInt(time) * 1000
	} else {
		time = +time
	}
	const d = new Date(time)
	const now = Date.now()
	if (detailed) {
		const diff = (now - d) / 1000

		if (diff < 30) {
			return '刚刚'
		} else if (diff < 3600) {
			// less 1 hour
			return Math.ceil(diff / 60) + '分钟前'
		} else if (diff < 3600 * 24) {
			return Math.ceil(diff / 3600) + '小时前'
		} else if (diff < 3600 * 24 * 2) {
			return '1天前'
		}
	}

	if (option) {
		return parseTime(time, option)
	} else {
		return d.getMonth() + 1 + '月' + d.getDate() + '日' + d.getHours() + '时' + d.getMinutes() + '分'
	}
}
/* 获取图片宽高 */
export function getImgInfo(fileUrl) {
	let img = new Image();
	img.src = fileUrl;
	img.style.display = "none";
	img.onload = () => {
		return img
	};
}