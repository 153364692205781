<template>
	<section class="app-main" :style="{'width':$store.state.mainWidth+'px'}">
		<transition name="fade-transform" mode="out-in">
			<keep-alive :include="includeView">
				<router-view :key="key" />
			</keep-alive>
		</transition>
	</section>
</template>

<script>
	export default {
		name: 'AppMain',
		computed: {
			// cachedViews() {
			// 	return this.$store.state.cachedViews
			// },
			key() {
				return this.$route.path
			}
		},
		data() {
			return {
				includeView: ['Search', 'JobList', 'Recommend', 'JobApply', 'DownLoad', 'Interview', 'Fav', 'Browsing']
			}
		}
	}
</script>

<style scoped>
	.app-main {
		margin: auto;
	}
</style>