<template>
  <div>
    <el-card :body-style="{ padding: '0px 0px 2px 0px' }" class="titleCard company-detail-section">
      <slot class="title-card-title" name="title" v-if="isSlot"></slot>
      <h3 v-else class="title-card-title" :style="{ height: height }">{{ title }}</h3>
      <slot></slot>
    </el-card>
  </div>
</template>

<script>
export default {
  name: 'TitleCard',
  props: {
    title: {
      type: String,
      default: ''
    },
    height: {
      type: String,
      default: 'auto'
    },
    isSlot: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {};
  }
};
</script>

<style>
.titleCard {
  margin-bottom: 20px;
  border-radius: 10px;
}

.title-card-title {
  font-size: 16px;
  font-weight: 500;
  color: #222;
  line-height: 22px;
  padding: 12px 24px;
  background: linear-gradient(90deg, #f5fcfc 0, #fcfbfa 100%);
  margin-bottom: 0;
  border-radius: 12px 12px 0 0;
  display: flex;
  align-items: center;
  margin: 0px;
}
</style>
