var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"header"}},[_c('div',{staticClass:"page-inner flex"},[_c('div',{staticClass:"logo"}),_c('p',{staticClass:"logo-corner"}),_c('div',{staticClass:"top-nav flexElm",style:({ width: _vm.navWidth + 'px' })},[_c('div',{staticClass:"flex",staticStyle:{"font-weight":"600"}},[_c('div',[_vm._v(_vm._s(_vm.$route.meta.title))]),_c('i',{staticClass:"el-icon-refresh",staticStyle:{"margin-left":"20px","font-size":"21px"},attrs:{"title":"刷新当前页"},on:{"click":_vm.reflashCur}})]),_c('div',[_vm._l((_vm.funcList),function(item,index){return _c('div',{key:index,staticClass:"nav-item top-vip-entry",attrs:{"id":'header_1_' + index}},[_c('div',{staticClass:"label-name"},[(item.id == '0')?_c('el-badge',{class:{
                active: '0' == _vm.$store.state.currMemut
              },staticStyle:{"vertical-align":"revert"},attrs:{"value":_vm.$store.state.msgCount,"max":99,"hidden":_vm.$store.state.msgCount <= 0}},[_c('router-link',{staticStyle:{"padding-right":"5px"},attrs:{"to":""},nativeOn:{"click":function($event){return _vm.check_login(item)}}},[_vm._v(" "+_vm._s(item.name)+" ")])],1):(item.id == '6')?_c('el-badge',{class:{
                active: item.id == _vm.$store.state.currMemut
              },staticStyle:{"vertical-align":"revert"},attrs:{"value":_vm.$store.state.resumeCount,"max":99,"hidden":_vm.$store.state.resumeCount <= 0}},[_c('router-link',{staticStyle:{"padding-right":"5px"},attrs:{"to":{
                  path: '/company/resume/jobApply',
                  query: { noSee: '1' }
                }}},[_vm._v(" "+_vm._s(item.name)+" ")])],1):_c('router-link',{class:{
                active: item.id === _vm.$store.state.currMemut
              },attrs:{"to":item.path},nativeOn:{"click":function($event){return _vm.funcClick(item)}}},[_vm._v(" "+_vm._s(item.name)+" ")])],1)])}),_c('el-dropdown',[_c('div',{staticClass:"nav-item top-vip-entry"},[_c('div',{staticClass:"label-name"},[_vm._v("帮助与反馈")])]),_c('el-dropdown-menu',{attrs:{"slot":"dropdown"},slot:"dropdown"},[_c('el-dropdown-item',{nativeOn:{"click":function($event){return _vm.feedback.apply(null, arguments)}}},[_vm._v("意见反馈")]),_c('el-dropdown-item',{nativeOn:{"click":function($event){return _vm.myKefu1.apply(null, arguments)}}},[_vm._v("在线客服")])],1)],1),_c('div',{staticClass:"nav-item top-vip-entry"},[_vm._v("|")]),_c('el-dropdown',[_c('div',{staticClass:"nav-item top-vip-entry"},[_c('div',{staticClass:"label-name",staticStyle:{"font-weight":"bold"}},[_vm._v(" "+_vm._s(_vm.$store.state.companyName || '')+" "),_c('img',{attrs:{"src":_vm.companyLogo}}),_c('i',{staticClass:"el-icon-arrow-down el-icon--right"})])]),_c('el-dropdown-menu',{staticStyle:{"width":"140px"},attrs:{"slot":"dropdown"},slot:"dropdown"},[_c('el-dropdown-item',[_c('router-link',{attrs:{"to":"/company/manage/baseInfo"}},[_c('i',{staticClass:"el-icon-office-building"}),_vm._v(" 企业信息 ")])],1),_c('el-dropdown-item',[_c('router-link',{attrs:{"to":"/company/account"}},[_c('i',{staticClass:"el-icon-setting"}),_vm._v(" 账号设置 ")])],1),_c('el-dropdown-item',[_c('router-link',{attrs:{"to":"/company/words"}},[_c('i',{staticClass:"el-icon-picture-outline-round"}),_vm._v(" 常用语 ")])],1),_c('el-dropdown-item',{attrs:{"divided":""},nativeOn:{"click":function($event){return _vm.logout1.apply(null, arguments)}}},[_c('i',{staticClass:"el-icon-mouse"}),_vm._v(" 退出登录 ")])],1)],1)],2)])]),_c('feedback',{ref:"Feedback"}),_c('vip-dialog',{ref:"VipDialog"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }