import router from './router'
import store from './store'

const whiteList = ['/hqyl/springFlow', '/hqyl/policyDetail', '/user/manage'] // no redirect whitelist
router.beforeEach(async (to, from, next) => {
	if (to.fullPath === '/user/login' && from.fullPath != '/user/register') {
		localStorage.setItem('currentRoute', from.fullPath)
	}
	// 每次打开应用时检查是否有存储的登录信息
	let _uid = localStorage.getItem('uid')
	let _utype = localStorage.getItem('utype')
	if (_uid && _utype === '1') {
		if (to.path.indexOf('/company') > -1) {
			if (to.path === '/user/login') { // 如果已登录，重定向到主页
				next(`/company`)
			} else {
				next()
			}
		} else if (whiteList.indexOf(to.path) > -1) {
			next()
		} else {
			// store.state.isLogin = true
			// store.state.isLogin2 = true
			next()
			// store.commit('clearLocation')
			// store.state.isLogin = false
			// router.push('/')
			// next(from.path)
		}
	} else {
		// store.state.isLogin = false
		if (to.path.indexOf('/company') > -1) {
			next(from.path)
		} else {
			next()
		}
		// if (whiteList.indexOf(to.path) === -1) { // 在免登录白名单，直接进入
		// 	next()
		// } else {
		// 	next(`/dashboard?redirect=${to.path}`) // 否则全部重定向到首页
		// }
	}
})