<template>
	<div class="content">
		<div v-show="!multiple">
			<a href="javascript:void(0);" :data-id="item.id" :class="item.id === id ?'selected':''"
				v-for="(item,index) in arryData" :key="index" @click="tagClick(item)">{{item.name}}</a>
		</div>
		<div v-show="multiple">
			<a href="javascript:void(0);" :data-id="item.id" :class="getMultiple(item)" v-for="(item,index) in arryData"
				:key="index" @click="tagClick1(item)">{{item.name}}</a>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'Tag',
		props: {
			arryData: {
				type: Array,
				default () {
					return []
				}
			},
			id: {
				type: String,
				default: ''
			},
			multiple: {
				type: Boolean,
				default: false
			}
		},
		watch: {
			id(val) {
				this.value = val
			},
			multipleList(list) {
				if (list.length === 0) {
					this.multipleList.push('')
				}
			}
		},
		data() {
			return {
				value: this.id,
				multipleList: [''],
				multiple_count: 2,
			}
		},
		methods: {
			tagClick(item) {
				this.value = item.id
				this.$emit('update:id', item.id)
				this.$emit('change', item.id, item)
			},
			tagClick1(item) {
				if (item.id === '') { //不限
					this.multipleList.length = 0
				}
				const _index = this.multipleList.findIndex(v => {
					return v === item.id
				})
				if (_index === -1) {
					const _index1 = this.multipleList.findIndex(v => {
						return v === ''
					})
					if (_index1 > -1) {
						this.multipleList.splice(_index1, 1)
					}
					if (this.multipleList.length >= this.multiple_count) {
						this.$message.warning(`最多选择${this.multiple_count}个`)
						return
					}
					this.multipleList.push(item.id)
				} else {
					this.multipleList.splice(_index, 1)
				}
				this.value = this.multipleList.join(',')
				this.$emit('update:id', this.value)
				this.$emit('change', item.id, item)
			},
			getMultiple(item) {
				return this.multipleList.indexOf(item.id) > -1 ? 'selected' : ''
			}
		}
	}
</script>

<style scoped>
	.content {
		color: #333;
		display: block;
		overflow: hidden;
		line-height: 25px;
		font-size: 15px;
		margin-top: -1px;
	}

	.content .selected {
		color: #00a6a7;
		font-weight: 650;
	}

	.content a {
		margin-right: 2px;
		display: inline-block;
		padding: 0 8px;
		color: #606266;
	}

	.content a:hover {
		color: #00a6a7;
	}
</style>