import CryptoJS from './crypto-js.min.js'
/* eslint-disable */
function encrypt(ss) {
	let text = ss+''; //明文
	if (text) {
		let key = CryptoJS.enc.Utf8.parse('xd8AtTMY');
		try {
			let encrypted = CryptoJS.DES.encrypt(text, key, buildConfig());
			let encode = 'Base64';  //Base64
			let cipherText = encrypted.toString();
			if (encode === 'HEX') {
				cipherText = CryptoJS.enc.Hex.stringify(CryptoJS.enc.Base64.parse(cipherText));
			}
			// console.log(cipherText) ;  //密文
			return cipherText
		} catch (e) {
			alert(e)
		}
	}
}

function decrypt(ss) {
	let text = ss+'';//密文
		let encode = 'Base64'; //Base64
		let cipherText;
		if (encode === 'Base64') {
			cipherText = CryptoJS.enc.Base64.parse(text)
		} else if (encode === 'HEX') {
			cipherText = CryptoJS.enc.Hex.parse(text)
		}
		let key = CryptoJS.enc.Utf8.parse('xd8AtTMY');
		try {
			let decrypted = CryptoJS.DES.decrypt({
				ciphertext: cipherText
			}, key, buildConfig());
			  return decrypted.toString(CryptoJS.enc.Utf8)
		} catch (e) {
			// alert(e)
		}
}

function buildConfig() {
	let mode = 'CBC';  //模式
	let pad = 'Pkcs7'; //填充
	let conf = {};
	switch (mode) {
		case 'CBC':
			conf.mode = CryptoJS.mode.CBC;
			break;
		case 'CFB':
			conf.mode = CryptoJS.mode.CFB;
			break;
		case 'CTR':
			conf.mode = CryptoJS.mode.CTR;
			break;
		case 'OFB':
			conf.mode = CryptoJS.mode.OFB;
			break;
		case 'ECB':
			conf.mode = CryptoJS.mode.ECB;
			break;
	}
	switch (pad) {
		case 'Pkcs7':
			conf.padding = CryptoJS.pad.Pkcs7;
			break;
		case 'Iso97971':
			conf.padding = CryptoJS.pad.Iso97971;
			break;
		case 'AnsiX923':
			conf.padding = CryptoJS.pad.AnsiX923;
			break;
		case 'Iso10126':
			conf.padding = CryptoJS.pad.Iso10126;
			break;
		case 'ZeroPadding':
			conf.padding = CryptoJS.pad.ZeroPadding;
			break;
		case 'NoPadding':
			conf.padding = CryptoJS.pad.NoPadding;
			break;
	}
	conf.iv = CryptoJS.enc.Utf8.parse('t2bW8lmA');
	return conf
}

export {
 encrypt,
 decrypt
}