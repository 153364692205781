import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import MetaInfo from 'vue-meta-info';
import lottie from 'lottie-web';
Vue.use(MetaInfo);
import { setupElement } from './config/element-setup.js';
setupElement(Vue);

import { message } from './utils/resetMessage';
import Basic from '@/components/index'; // 引入基础组件
import '@/permission';
import http from '@/utils/http';
import Print from '@/utils/print';
import imClient from '@/utils/js/im'; //im消息
import SlideVerify from 'vue-monoplasty-slide-verify';
import { expr } from '@/utils/validate';
import { closeLoading, showLoading, notify, isLogin } from '@/utils/msg';
Vue.prototype.$lottie = lottie; //动画json返回svg图标
Vue.prototype.eventBus = new Vue(); //注册全局事件对象

router.beforeEach((to, from, next) => {
  window.document.title =
    to.meta.title == undefined ? '洹畔人才网' : to.meta.title + ' - 洹畔人才网';
  const isMobile = to.matched.some((record) => record.meta.isMobile); // 判断目标路由是否为手机设备路由
  if (isMobile) {
    // 如果目标路由为手机设备路由
    // 这里可以根据实际需求进行跳转，例如直接跳转到H5链接
    next();
    // window.open(`https://hprcw.net/h5/#/pagesB/common/zph?id=${to.query.id||''}`); // 示例跳转到H5链接路径
  } else {
    // 如果目标路由不是手机设备路由
    next(); // 继续正常导航
  }
});

import MenuNav from './components/MenuNav';
import Pagination from './components/Pagination';
import CompanyPublicTitle from './components/PublicTitle';
import Loading from '@/components/common/Loading.vue';
Vue.component('Loading', Loading);
Vue.component('menu-nav', MenuNav);
Vue.component('pagination', Pagination);
Vue.component('company-title', CompanyPublicTitle);

import VueCanvasPoster from 'vue-canvas-poster';
Vue.use(VueCanvasPoster);

Vue.config.productionTip = false;
Vue.use(SlideVerify); //滑块验证
Vue.use(Basic);
Vue.use(Print); // 打印
Vue.mixin(imClient);
Vue.prototype.$loadCount = 0; // 显示查询状态计数
Vue.prototype.$http = http;
Vue.prototype.$expr = expr;
Vue.prototype.$message = message;
//全屏指令加载
Vue.prototype.$loadindInstance = undefined;
Vue.prototype.$msg = notify;
Vue.prototype.$isLogin = isLogin;
Vue.prototype.$showLoading = showLoading;
Vue.prototype.$closeLoading = closeLoading;

// 封装一个v-intro-if指令，这样就可以在循环中展示符合特定条件的引导了
Vue.directive('intro-if', {
  bind: function (el, binding) {
    if (binding.value === false) {
      delete el.dataset.intro;
      delete el.dataset.hint;
    }
  }
});

window.$vue = new Vue({
  router,
  store,
  render: (h) => h(App),
  created() {
    document.dispatchEvent(new Event('render-event')); //预渲染触发时机
  }
  // // 预渲染
  // mounted() {
  // 	document.dispatchEvent(new Event('render-event'))
  // }
}).$mount('#app');
