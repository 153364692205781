<template>
	<div class="search_title">
		<div class="search_1">
			<div class="input-with-select">
				<el-input placeholder="请输入职位名称/公司名称" v-model="searchVal" @keyup.enter.native="searchData">
					<el-select v-model="city" filterable slot="prepend" placeholder="请选择">
						<el-option v-for="item in citys" :key="item.id" :label="item.name" :value="item.id">
						</el-option>
					</el-select>
					<!-- <slot name="prepend" slot="prepend"></slot> -->
					<el-button slot="append" class="search_box_index" @click="searchData">搜索</el-button>
				</el-input>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'SearchLan',
		props: {
			value: {
				type: String,
				default: ''
			}
		},
		data() {
			return {
				city: '',
				citys: [],
				searchVal: '',
			}
		},
		watch: {
			value(val) {
				this.searchVal = val
			}
		},
		mounted() {
			this.citys = localStorage.getItem('diqu') ? JSON.parse(localStorage.getItem('diqu')) : []
			this.citys.unshift({
				id: '',
				name: '不限'
			})
		},
		methods: {
			//点击搜索
			searchData() {
				this.$emit('update:value', this.searchVal)
				this.$emit('search', this.searchVal, this.city)
			}
		}
	}
</script>

<style scoped>
	.search_title {
		width: 100%;
		margin: 0px auto;
	}

	/* 搜索栏样式 */
	.search_1 {
		width: 100%;
		text-align: center;
	}

	.input-with-select {
		border: 2px solid #00bebd;
		border-radius: 10px;
		background: #fff;
	}

	::v-deep .search_1 .el-input-group__prepend {
		border-radius: 10px;
		border: none;
		padding: 11px 18px;
		width: 59px;
		background: #fff;
	}

	::v-deep .search_1 .el-input__inner {
		border-radius: 10px;
		/* height: 50px; */
		border: none;
	}

	::v-deep .search_1 .el-input-group__append {
		width: 128px;
		background: #00bebd;
		border-radius: 0 5px 5px 0;
		color: white;
		font-weight: 700;
		border: none;
	}

	@media screen and (max-width: 768px) {
		.search_title {
			width: 95% !important;
		}
	}
</style>
