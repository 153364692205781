/**
 * http.js
 * 封装axios，
 * 调用方法:
 * http.get('/api/enquiry/web/query',{id:1}).then((res)=>{你的操作})
 * http.post('/api/enquiry/web/update',{id:1}).then((res)=>{你的操作})
 * http.postFormData('/api/enquiry/web/update',{id:1,file:file}).then((res)=>{你的操作})
 */
import axios from 'axios'
/* eslint-disable */
import {
	decrypt
} from './js/2222.js'
const service = axios.create({
	baseURL: window.global.RequestBaseUrl,
	// withCredentials: true, // 跨域支持发送cookie
	timeout: window.global.RequestTimeout // 请求超时时间
})

function closeLoad() {
	var timer = setTimeout(function() {
        window.$vue.$store.commit('setLoading', false);

		if (window.$vue.$loadCount > 0) {
			window.$vue.$loadCount--
		}
		if (window.$vue.$loadCount === 0) {
			window.$vue.$closeLoading()
		}
		clearTimeout(timer)
	}, 10)
}

export default {
	/**
	 * get方法，对应get请求
	 * @param {String} url [请求的url地址]
	 * @param {Object} params [请求时携带的参数]
	 */
	get(url, params) {
		return new Promise((resolve, reject) => {
			// window.$vue.$loadCount++
			// if (window.$vue.$loadCount === 1) {
			// 	window.$vue.$showLoading('请求中...')
			// }
            window.$vue.$store.commit('setLoading', true);
            let _parameter = {}
            if(process.env.NODE_ENV != 'production'){
                _parameter = Object.assign({jm: '1'}, params)
            }else{
                _parameter = params || {}
            }
			service
				.get(url, {
					headers: {},
					params: _parameter
				})
				.then((res) => {
					closeLoad()
					const _response = JSON.parse(decrypt(res.data))
					if (_response.success == '1') {
						if (typeof _response === 'object') {
							resolve(_response)
						} else {
							resolve(JSON.parse(_response))
						}
					} else {
						reject(_response)
					}
				})
				.catch((err) => {
					closeLoad()
					if (url !== '/hash' && window.$vue.$loadCount === 1) {
						const msg = err.message === 'Network Error' ? '网络异常，请稍后重试' : err.message
						window.$vue.$message.error(msg)
						reject(decrypt(err))
					}
				})
		})
	},
	/**
	 * post方法，对应post请求
	 * @param {String} url [请求的url地址]
	 * @param {Object} data [请求时携带的参数]
	 */
	post(url, data, lodding = true) {
		return new Promise((resolve, reject) => {
			if (url !== '/hash' && lodding) {
				// window.$vue.$loadCount++
				// if (window.$vue.$loadCount === 1) {
				// 	window.$vue.$showLoading('请求中...')
				// }
                window.$vue.$store.commit('setLoading', true);
			}
            let _parameter = {}
            if(process.env.NODE_ENV != 'production'){
                _parameter = Object.assign({jm: '1'}, data)
            }else{
                _parameter =data || {}
            }
			service.post(url, _parameter, {
					headers: {},
					transformRequest: [function(data) {
						// 对 data 进行任意转换处理
						const formData = new FormData()
						Object.keys(data).forEach((key) => {
							formData.append(key, data[key])
						})
						return formData
					}],
				}).then((res) => {
					if (lodding) {
						closeLoad()
					}
                    let _response
					try {
						if (typeof(res.data) == 'object') {
							_response = res.data
						} else {
							_response = JSON.parse(decrypt(res.data))
						}

                        if (res.status == 200 && _response) {
                            resolve(_response)
                            // if (_response.success === '1' || _response.success === '2') {
                            // 	resolve(_response)
                            // } else {
                            // 	resolve(_response)
                            // }
                        } else {
                            window.$vue.$msg('请求错误，请联系管理员', 'error')
                        }
					} catch (e) {
						window.$vue.$msg('Json数据异常：' + e, 'error')
					}
				})
				.catch((err) => {
					closeLoad()
					if (url !== '/hash' && window.$vue.$loadCount === 1) {
						const msg = err.message === 'Network Error' ? '网络异常，请稍后重试' : err.message
						window.$vue.$message.error(msg)
						reject(decrypt(err))
					}
				})
		})
	},
	/**
	 * postFormData方法，对应post请求，用来提交文件+数据
	 * @param {String} url [请求的url地址]
	 * @param {Object} params [请求时携带的参数]
	 */
	postFormData(url, params) {
		return new Promise((resolve, reject) => {
            let _parameter = {}
            if(process.env.NODE_ENV != 'production'){
                _parameter = Object.assign({jm: '1'}, params)
            }else{
                _parameter = params || {}
            }
			service({
					headers: {},
					transformRequest: [
						function(data) {
							// 在请求之前对data传参进行格式转换
							const formData = new FormData()
							Object.keys(data).forEach((key) => {
								formData.append(key, data[key])
							})
							return formData
						}
					],
					url: url,
					method: 'post',
					data: _parameter
				})
				.then((res) => {
                    let _response
                    if (typeof(res.data) == 'object') {
                        _response = res.data
                    } else {
                        _response = JSON.parse(decrypt(res.data))
                    }
                    if (res.status == 200 && _response) {
                        resolve(_response)
                    } else {
                        window.$vue.$msg('上传请求错误，请联系管理员', 'error')
                    }
				})
				.catch((err) => {
					closeLoad()
					if (url !== '/hash' && window.$vue.$loadCount === 1) {
						const msg = err.message === 'Network Error' ? '网络异常，请稍后重试' : err.message
						window.$vue.$message.error(msg)
						reject(decrypt(err))
					}
				})
		})
	},

	his(url, data, token) {
		return new Promise((resolve, reject) => {
            let _parameter = {}
            if(process.env.NODE_ENV != 'production'){
                _parameter = Object.assign({jm: '1'}, data)
            }else{
                _parameter = data || {}
            }
			service
				.post(url, _parameter, {
					headers: {
						'token': token,
						'Accept': 'application/json;charset=UTF-8',
						'Content-Type': 'application/json;charset=UTF-8',
					},
				})
				.then((res) => {
					if (res.status === 200) {
						resolve(res.data)
					} else {
						window.$vue.$msg('请求错误，请联系管理员', 'error')
					}
					// resolve(res.data)
				}).catch((err) => {
					closeLoad()
					if (url !== '/hash' && window.$vue.$loadCount === 1) {
						const msg = err.message === 'Network Error' ? '网络异常，请稍后重试' : err.message
						window.$vue.$message.error(msg)
						reject(decrypt(err))
					}
				})
		})
	},
	his1(url, data, token) {
		return new Promise((resolve, reject) => {
			service
				.get(url, {
					headers: {
						'token': token,
						'Accept': 'application/json;charset=UTF-8',
						'Content-Type': 'application/json;charset=UTF-8',
					},
				})
				.then((res) => {
					if (res.status === 200) {
						resolve(res.data)
					} else {
						window.$vue.$msg('请求错误，请联系管理员', 'error')
					}
				})
				.catch((err) => {
					closeLoad()
					if (url !== '/hash' && window.$vue.$loadCount === 1) {
						const msg = err.message === 'Network Error' ? '网络异常，请稍后重试' : err.message
						window.$vue.$message.error(msg)
						reject(decrypt(err))
					}
				})
		})
	}
}