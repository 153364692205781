/**重置message，防止重复点击重复弹出message弹框 */
import {
	Message,Notification 
} from 'element-ui'
let messageInstance = null;
let mainMessage = function DoneMessage(options) {
	//如果弹窗已存在先关闭
	if (messageInstance) {
		messageInstance.close();
	}
	messageInstance = Message(options);
}
const $notify = options => {
  return Notification({
    ...options,
    duration: 0
  })
}
let arr = ['success', 'warning', 'info', 'error'];
arr.forEach(function(type) {
	mainMessage[type] = function(options) {
		if (typeof options === 'string') {
			options = {
				message: options
			};
		}
		options.type = type;
		return mainMessage(options);
	};
});
['success', 'warning', 'info', 'error'].forEach(type => {
  $notify[type] = options => {
    if (typeof options === 'string') {
      options = {
        message: options,
        type: type,
        title: '提示',
        duration: 0
      }
    } else {
      options = Object.assign({
        title: '提示',
        type: type,
        duration: 0
      }, options)
    }
    return Notification(options)
  }
})

Notification.install = function (Vue) {
  Vue.prototype.$notify = $notify
  Vue.prototype.$notify.close = Notification.close
}
export const message = mainMessage
export const notify1 = Notification
