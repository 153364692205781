import introJs from 'intro.js' // introjs库 新手引导
import 'intro.js/introjs.css' // introjs默认css样式
// introjs还提供了多种主题，可以通过以下方式引入
import 'intro.js/themes/introjs-modern.css' // introjs主题

const intro = introJs()

intro.setOptions({
	prevLabel: '上一步',
	nextLabel: '下一步',
	skipLabel: '跳过',
	doneLabel: '完成',
	tooltipClass: 'intro-tooltip',
	highlightClass: 'intro-highlight', //说明高亮区域的样式
	exitOnEsc: false, //是否使用键盘Esc退出
	exitOnOverlayClick: false, //是否允许点击空白处退出
	keyboardNavigation: true, //是否允许键盘来操作
	showBullets: false, //是否使用点显示进度
	showProgress: true, //是否显示进度条
	scrollToElement: true, //是否滑动到高亮的区域
	overlayOpacity: 0.7, // 遮罩层的透明度 0-1之间
	// helperElementPadding: 6, //提示边框的padding
	hintPosition: 'top-middle', //默认提示位置
	tooltipPosition: 'bottom', //引导说明框相对高亮说明区域的位置
	positionPrecedence: ['bottom', 'top', 'right', 'left'], //当位置选择自动的时候，位置排列的优先级
	disableInteraction: false, //是否禁止与元素的相互关联
	hidePrev: true, //是否在第一步隐藏上一步
	// hideNext: true, /* 是否在最后一步隐藏下一步 */
	steps: [],
})

export default intro;