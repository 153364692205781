import 'element-ui/lib/theme-chalk/index.css'
import 'element-ui/lib/theme-chalk/display.css';
import {
	Pagination,
	Dialog,
	Dropdown,
	DropdownMenu,
	DropdownItem,
	Menu,
	Submenu,
	MenuItem,
	Input,
	InputNumber,
	Radio,
	RadioGroup,
	Checkbox,
	Switch,
	Select,
	Option,
	Button,
	Table,
	TableColumn,
	DatePicker,
	TimeSelect,
	Popover,
	Tooltip,
	Form,
	FormItem,
	Tabs,
	TabPane,
	Tag,
	Tree,
	Drawer,
	Alert,
	Icon,
	Row,
	Col,
	Upload,
	Progress,
	Badge,
	Card,
	Steps,
	Step,
	Carousel,
	CarouselItem,
	Collapse,
	CollapseItem,
	Cascader,
	Container,
	Header,
	Aside,
	Main,
	Footer,
	Timeline,
	TimelineItem,
	Link,
	Divider,
	Image,
	Backtop,
	CascaderPanel,
	Empty,
	Autocomplete,
	Skeleton,
	SkeletonItem,
	Descriptions,
	DescriptionsItem,
	Popconfirm,
	Loading,
	MessageBox,
	Notification
} from 'element-ui';
Dialog.props.lockScroll.default = false;

const components = [
	Pagination, Dialog, Dropdown, DropdownMenu, DropdownItem, Menu, Submenu, MenuItem, Input, InputNumber, Radio,
	RadioGroup, Checkbox, Switch, Select, Option, Button, Table, TableColumn, DatePicker, TimeSelect, Popover,
	Tooltip, Form, FormItem, Tabs, TabPane, Tag, Tree, Drawer, Alert, Icon, Row, Col, Upload, Progress, Badge, Card,
	Steps, Step, Carousel, CarouselItem, Collapse, CollapseItem, Cascader, Container, Header, Aside, Main, Footer,
	Timeline, TimelineItem, Link, Divider, Image, Backtop, Empty, Autocomplete, Skeleton, SkeletonItem,
	Descriptions, DescriptionsItem, Popconfirm, CascaderPanel
]

export function setupElement(vue) {
	// vue.prototype.$ELEMENT = {
	// 	size: 'small',
	// 	zIndex: 3000
	// };
	vue.use(Loading.directive);

	components.forEach(component => {
		if (component.name) { //排除message消息重复注册
			vue.use(component)
		}
	})
	vue.prototype.$loading = Loading;
	vue.prototype.$confirm = MessageBox.confirm;
	vue.prototype.$notify = Notification;
}